import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyC6qYBYWk6XWkAdO3w1do5JwfhaDyNu2u8',
  authDomain: 'codex-haverst-school.firebaseapp.com',
  projectId: 'codex-haverst-school',
  storageBucket: 'codex-haverst-school.appspot.com',
  messagingSenderId: '824650775667',
  appId: '1:824650775667:web:ead2fe6dd3c8f4e5d8be06',
  measurementId: 'G-481VJD1MM3',
};

firebase.initializeApp( config );

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const timeStamp = firebase.firestore.Timestamp;
export type TimeStamp = firebase.firestore.Timestamp;
export const fieldValue = firebase.firestore.FieldValue;
export type FieldValue = firebase.firestore.FieldValue;
export const authPersistenceLocal = firebase.auth.Auth.Persistence.LOCAL;
export const authPersistenceSession = firebase.auth.Auth.Persistence.SESSION;
export const emailAuthProvider = firebase.auth.EmailAuthProvider;
